import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, Theme, Typography } from '@material-ui/core';
import { tan19 } from '../../utils/utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: '#FFF',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.up(1200)]: {
        width: '388px',
      }
    },
    header: {
      position: 'relative',
      marginTop: `calc((100% * ${tan19}))`,
      marginBottom: `calc((100% * ${tan19} * -1) / 2 + 8px)`,
      paddingTop: `calc((100% * ${tan19} * -1))`,
      backgroundColor: theme.palette.primary.main,
      transform: 'skewY(-19deg)',
      overflow: 'hidden',
      borderBottomLeftRadius: '50px',
      [theme.breakpoints.up(1200)]: {
        borderBottomRightRadius: '25px',
      },
    },
    headerMask: {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      height: '10px',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    headerInner: {
      paddingTop: '40px',
      transform: 'skewY(19deg)',
      marginBottom: '-42px',
    },
    headerImage: {
      display: 'block',
      margin: '0 auto',
      width: '174px',
      height: '174px',
    },
    inner: {
      padding: '0 24px 24px',
    },
    title: {
      fontSize: '17px',
      lineHeight: '22px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      marginBottom: '16px',
    },
    featuresList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '40px',
    },
    feature: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '12px',
    },
    featureIcon: {
      display: 'block',
      width: '24px',
      height: '24px',
      borderRadius: '12px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    },
    featureText: {
      fontSize: '15px',
      lineHeight: '20px',
      color: '#4F4F4F',
      fontWeight: '400',
    },
    downloadButton: {
      backgroundColor: theme.palette.secondary.main,
      fontSize: '17px',
      lineHeight: '24px',
      fontWeight: '700',
      padding: '12px 0',
      width: '100%',
      borderRadius: '0px 8px 8px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      letterSpacing: '0',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
});

const CollectionDownloadComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const features = t('collectionPage.downloadComponent.features').split('|');

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerInner}>
          <img className={classes.headerImage} src="/img/collection-post/header.png" alt="" />
        </div>
        <div className={classes.headerMask} />
      </div>
      <div className={classes.inner}>
        <Typography className={classes.title} variant="body1">
          {t('collectionPage.downloadComponent.title')}
        </Typography>
        <div className={classes.featuresList}>
          <div className={classes.feature}>
            <img className={classes.featureIcon} src="/img/collection-post/features-1.svg" alt="" />
            <Typography className={classes.featureText} variant="body1">
              {features[0]}
            </Typography>
          </div>
          <div className={classes.feature}>
            <img className={classes.featureIcon} src="/img/collection-post/features-2.svg" alt="" />
            <Typography className={classes.featureText} variant="body1">
              {features[1]}
            </Typography>
          </div>
          <div className={classes.feature}>
            <img className={classes.featureIcon} src="/img/collection-post/features-3.svg" alt="" />
            <Typography className={classes.featureText} variant="body1">
              {features[2]}
            </Typography>
          </div>
          <div className={classes.feature}>
            <img className={classes.featureIcon} src="/img/collection-post/features-4.svg" alt="" />
            <Typography className={classes.featureText} variant="body1">
              {features[3]}
            </Typography>
          </div>
          <div className={classes.feature}>
            <img className={classes.featureIcon} src="/img/collection-post/features-5.svg" alt="" />
            <Typography className={classes.featureText} variant="body1">
              {features[4]}
            </Typography>
          </div>
        </div>
        <Button
          className={classes.downloadButton}
          variant="contained"
          color="primary"
          href="https://uptime.app.link/S5iL3GrK0cb"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('collectionPage.downloadComponent.cta')}
        </Button>
      </div>
    </div>
  );
}

export default CollectionDownloadComponent;
