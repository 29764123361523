import React from 'react';
import { Link } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@material-ui/core';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import HackLabel from '../HackLabel/HackLabel';

const useStyles = makeStyles(theme => {
  return {
    hackItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #D4D4D4',
      paddingBottom: '16px',
      textDecoration: 'none',
      '&:last-child': {
        borderBottom: '0',
        paddingBottom: '0',
      },
      [theme.breakpoints.up(1200)]: {
        paddingBottom: '24px',
      },
    },
    hackInner: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    hackCover: {
      width: '61px',
      height: '89px',
      border: '2px solid #FFF',
      borderRadius: '4px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.up(1200)]: {
        width: '114px',
        height: '172px',
        borderRadius: '4px',
      },
    },
    hackDetailsContainer: {
      paddingLeft: '12px',
      [theme.breakpoints.up(1200)]: {
        paddingLeft: '18px',
      },
    },
    hackTitle: {
      fontSize: '17px',
      lineHeight: '22px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      marginBottom: '4px',
      [theme.breakpoints.up(1200)]: {
        fontSize: '24px',
        lineHeight: '30px',
        marginBottom: '0',
      },
    },
    hackContribs: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: '400',
      color: '#4F4F4F',
    },
    chevronContainer: {
      borderRadius: '50%',
      backgroundColor: '#FFF',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      width: '24px',
      height: '24px',
      padding: '2.5px',
      [theme.breakpoints.up(1200)]: {
        width: '32px',
        height: '32px',
        padding: '4px',
      },
    },
    chevron: {
      width: '19px',
      height: '19px',
      [theme.breakpoints.up(1200)]: {
        width: '24px',
        height: '24px',
      }
    },
  };
});

const CollectionHackItem = ({
  hack
}) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.hackItem}
      to={hack.path}
    >
      <div className={classes.hackInner}>
        <img className={classes.hackCover} src={hack.coverUrl} alt="" />
        <div className={classes.hackDetailsContainer}>
          <HackLabel hackType={hack.type} />
          <Typography className={classes.hackTitle} variant="body1">
            {hack.title}
          </Typography>
          <Typography className={classes.hackContribs} variant="body1">
            {hack.contributors.map(({ name }) => {
              return name;
            }).join(', ')}
          </Typography>
        </div>
      </div>
      <div className={classes.chevronContainer}>
        <ChevronRightIcon className={classes.chevron} />
      </div>
    </Link>
  );
}

export default CollectionHackItem;
