/* eslint-disable no-unused-vars */
import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import CollectionHackItem from '../components/CollectionHackItem/CollectionHackItem';
import { getLexemFromTopicName, tan6 } from '../utils/utils';
import CollectionDownloadComponent from '../components/CollectionDownloadComponent/CollectionDownloadComponent';
import DownloadComponent from '../components/DownloadComponent/DownloadComponent';
import SEOHelmet from '../helmet/SEOHelmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import TranslatedContentComponent from '../components/TranslatedContentComponent/TranslatedContentComponent';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#F8F8F8',
    },
    heroContainer: {
      position: 'relative',
      backgroundColor: '#171F3A',
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6}))`,
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '0 0 25px 50px',
      marginBottom: `calc((100vw * ${tan6} * -1) / 2 + 32px)`,
      [theme.breakpoints.up(600)]: {
        paddingBottom: `calc((600px * ${tan6} * -1) / 2)`,
        marginBottom: `calc((600px * ${tan6} * -1) / 2 + 32px)`,
      },
      [theme.breakpoints.up(1200)]: {
        paddingBottom: `calc((1170px * ${tan6} * -1) / 2)`,
        marginBottom: `calc((1170px * ${tan6} * -1) / 2 + 56px)`,
      },
      overflow: 'hidden',
    },
    collectionColor: {
      position: 'absolute',
      background: ({ backgroundColor }) => backgroundColor,
      height: 'calc(100% + 10px)',
      width: '47%',
      top: '0',
      right: '0',
      clipPath: 'polygon(381px 0, 100% 0%, 100% 100%, 0% 100%)',
      [theme.breakpoints.up(1200)]: {
        width: '50%',
        top: '0',
        left: 'calc(50% + 300px)',
        clipPath: 'polygon(250px 0, 100% 0%, 100% 100%, 0% 100%)',
      },
    },
    heroContainerContent: {
      transform: 'skewY(6.733deg)',
      padding: '72px 24px 0',
      minHeight: 300,
      margin: '0 auto',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      color: '#fff',
      [theme.breakpoints.up(600)]: {
        paddingTop: '66px',
        maxWidth: '600px',
        minHeight: 300,
      },
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1218px',
        marginBottom: '80px',
        paddingTop: '128px',
      },
    },
    heroTextContainer: {
      [theme.breakpoints.up(1200)]: {
        maxWidth: '573px',
      }
    },
    pageTitle: {
      color: '#fff',
      fontSize: '34px',
      lineHeight: '48px',
      fontWeight: '700',
      marginBottom: '12px',
    },
    translatedContentComponent: {
      marginBottom: '12px',
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '8px',
      marginBottom: '24px',
    },
    tag: {
      padding: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      fontSize: '12px',
      lineHeight: '14px',
    },
    description: {
      color: '#fff',
      fontSize: '17px',
      lineHeight: '24px',
      fontWeight: '400',
      marginBottom: '56px',
      [theme.breakpoints.up(1200)]: {
        lineHeight: '28px',
        fontWeight: '500',
        marginBottom: '0',
      },
    },
    coversContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '28px',
      [theme.breakpoints.up(1200)]: {
        width: '376px',
        marginBottom: 0,
      }
    },
    coverImage: {
      display: 'block',
      border: '2px solid #fff',
      borderRadius: '4px',
      boxShadow: '10px 20px 20px rgba(0, 0, 0, 0.2)',
      '&:nth-child(1)': {
        width: '28%',
        height: '42%',
      },
      '&:nth-child(2)': {
        width: '35%',
        height: '53%',
        marginLeft: '-7.5%',
      },
      '&:nth-child(3)': {
        width: '52%',
        height: '76%',
        marginLeft: '-7.5%',
      },
      [theme.breakpoints.up(1200)]: {
        '&:nth-child(1)': {
          width: '107px',
          height: '159px',
        },
        '&:nth-child(2)': {
          width: '134px',
          height: '206px',
          marginLeft: '-31px',
        },
        '&:nth-child(3)': {
          width: '197px',
          height: '297px',
          marginLeft: '-31px',
        },
      },
    },
    content: {
      padding: '0 24px',
      maxWidth: '600px',
      margin: '0 auto',
      marginBottom: '32px',
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '1218px',
        gap: '128px',
        marginBottom: '72px',
      },
    },
    hacks: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '32px',
      [theme.breakpoints.up(1200)]: {
        flex: '1 0',
        gap: '24px',
        marginBottom: '0',
      },
    },
  };
});

const CollectionsPostPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation();
  const { collection, allHack: collectionHacks } = data;
  const classes = useStyles({ backgroundColor: collection?.backgroundColor || '#E0B310' });

  const covers = collectionHacks.nodes.slice(0, 3).map(hack => {
    return hack.coverUrl;
  }) || [];

  return (
    <>
      <SEOHelmet
        title={t('collectionPage.seo.title', { title: data?.collection.title })}
        description={t('collectionPage.seo.description', { description: data?.collection.description })}
        image={{
          url: data?.collection.coverUrl,
          alt: `${data?.collection.title} cover image`,
        }}
        location={location}
        locale={pageContext?.language}
        overrideTitle
      />
      <AppBar variant="navy" keepBackgroundColor />
      <main className={classes.root}>
        <div className={classes.heroContainer}>
          <div className={classes.collectionColor} />
          <div className={classes.heroContainerContent}>
            <div className={classes.heroTextContainer}>
              <Typography className={classes.pageTitle} variant="h1">
                {collection.title}
              </Typography>
              {collection.baseLocaleCollectionId !== collection.jsonId && (
                <div className={classes.translatedContentComponent}>
                  <TranslatedContentComponent variant="collection" color="dark" />
                </div>
              )}
              <div className={classes.tagsContainer}>
                {collection.tags?.map(tag => {
                  return (
                    <div key={tag} className={classes.tag}>
                      {t(getLexemFromTopicName(tag))}
                    </div>
                  );
                })}
              </div>
              <Typography className={classes.description} variant="body1">
                {collection.description}
              </Typography>
            </div>
            <div className={classes.coversContainer}>
              {covers.map(cover => {
                return (
                  <img key={cover} className={classes.coverImage} src={cover} alt="" />
                )
              })}
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.hacks}>
            {collectionHacks?.nodes?.map(hack => {
              return (
                <CollectionHackItem key={hack.jsonId} hack={hack} />
              );
            })}
          </div>
          <div className={classes.downloadComponent}>
            <CollectionDownloadComponent />
          </div>
        </div>
        <DownloadComponent showPhones />
      </main>
      <Footer />
    </>
  );
};

export default CollectionsPostPage;

export const query = graphql`
  query CollectionPostQuery($collectionId: String, $hackIds: [String], $language: String) {
    collection(jsonId: { eq: $collectionId }) {
      jsonId
      baseLocaleCollectionId
      backgroundColor
      description
      coverUrl
      title
      tags
    }
    allHack(filter: { jsonId: { in: $hackIds } }) {
      nodes {
        jsonId
        coverUrl
        path
        title
        contributors {
          name
        }
        type
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
