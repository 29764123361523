import { makeStyles } from '@material-ui/core';
import React from 'react';
import LanguageIcon from '../icons/LanguageIcon';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderTop: '1px solid #E9E9EC',
      borderBottom: '1px solid #E9E9EC',
      gap: '4px',
      padding: '2px 0',
    },
    icon: {
      color: ({ color }: { color: string }) => color === 'light' ? '#4F4F4F' : '#FFF',
      fontSize: '16px',
    },
    inner: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px',
      minHeight: '34px',
    },
    text: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: '400',
      color: ({ color }: { color: string }) => color === 'light' ? '#4F4F4F' : '#FFF',
      margin: 0,
    },
    link: {
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '700',
      color: ({ color }: { color: string }) => color === 'light' ? '#1F2744' : '#FFF',
    },
  }
});

const TranslatedContentComponent = ({
  variant = 'hack',
  color = 'light',
}) => {
  const classes = useStyles({ color });
  const { defaultLanguage, originalPath } = useI18next();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <LanguageIcon className={classes.icon} />
      <div className={classes.inner}>
        <p className={classes.text}>{t(`${variant}Page.banner.language`)}</p>
        <Link className={classes.link} to={originalPath} language={defaultLanguage}>{t(`${variant}Page.banner.original`)}</Link>
      </div>
    </div>
  )
}

export default TranslatedContentComponent;
